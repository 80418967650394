import { ElNotification } from 'element-plus'
import type {
  NotificationParams,
  NotificationOptions,
} from 'element-plus/es/components/notification/src/notification'

import SuccessIcon from '@base/assets/img/svg/icon-success.svg'
import ErrorIcon from '@base/assets/img/svg/icon-error.svg'
import WarningIcon from '@base/assets/img/svg/icon-warning.svg'
import InfoIcon from '@base/assets/img/svg/icon-info.svg'

export type NotifyType =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'tx-success'
  | 'tx-error'

export type UseNotifyOptions = Omit<
  Extract<NotificationParams, Partial<NotificationOptions>>,
  'type'
> & {
  type?: NotifyType
}

const msgEnums: Record<NotifyType, string> = {
  success: 'Operation Successful',
  error: 'Operation Failed',
  'tx-success': 'Transaction Confirmed',
  'tx-error': 'Transaction Failed',
  warning: 'Warning',
  info: 'Information',
} as const

/**
 * @description Global message notification, default type is 'tx-success'
 */
export function useNotify(options?: UseNotifyOptions) {
  const type = options?.type || 'tx-success'
  const { type: _, ...otherOptions } = options || {}
  const customOptions = getNotificationOptions(options?.type)

  return ElNotification({
    message: msgEnums[type],
    showClose: false,
    customClass: `ui-notification ui-notification--${customOptions.type}`,
    type: '', // do not use type because it will override custom icon
    icon: customOptions.icon,
    ...otherOptions,
  })
}

interface CustomNotificationOptions {
  type: 'success' | 'error' | 'info' | 'warning' | ''
  icon: string
}

function getNotificationOptions(type: NotifyType | undefined) {
  switch (type) {
    case 'success':
    case 'tx-success':
      return {
        type: 'success',
        icon: SuccessIcon,
      } as unknown as CustomNotificationOptions
    case 'error':
    case 'tx-error':
      return {
        type: 'error',
        icon: ErrorIcon,
      } as unknown as CustomNotificationOptions
    case 'info':
      return {
        type: 'info',
        icon: InfoIcon,
      } as unknown as CustomNotificationOptions
    case 'warning':
      return {
        type: 'warning',
        icon: WarningIcon,
      } as unknown as CustomNotificationOptions
    default:
      return {
        type: '',
        icon: InfoIcon,
      } as unknown as CustomNotificationOptions
  }
}
